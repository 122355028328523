@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap');


@layer base {
  html {
    scroll-behavior: smooth;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }

  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  .inknut-antiqua-regular {
    font-family: "Inknut Antiqua", serif;
    font-weight: 700;
    font-style: normal;
  }

  .staggered-item {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    .staggered-item:nth-child(5) {
      margin-top: -25rem;
    }

    .staggered-item:nth-child(7) {
      margin-top: -25rem;
    }

    .staggered-item:nth-child(9) {
      margin-top: -14rem;
    }

    .staggered-item:nth-child(11) {
      margin-top: -14rem;
    }

  }

  /* For large devices (lg) */
  @media (min-width: 1024px) {
    .staggered-item:nth-child(2) {
      margin-top: 3rem;
    }

    .staggered-item:nth-child(4) {
      margin-top: 3rem;
    }

    .staggered-item:nth-child(5) {
      margin-top: -19rem;
    }

    .staggered-item:nth-child(6) {
      margin-top: -16rem;
    }

    .staggered-item:nth-child(7) {
      margin-top: -19rem;
    }

    .staggered-item:nth-child(8) {
      margin-top: -0.3rem;
    }

    .staggered-item:nth-child(9) {
      margin-top: -19rem;
    }

    .staggered-item:nth-child(10) {
      margin-top: -16rem;
    }

    .staggered-item:nth-child(11) {
      margin-top: -12rem;
    }
  }

  @media (min-width: 1440px) {
    .staggered-item:nth-child(2) {
      margin-top: 4rem;
    }

    .staggered-item:nth-child(4) {
      margin-top: 4rem;
    }

    .staggered-item:nth-child(5) {
      margin-top: -29rem;
    }

    .staggered-item:nth-child(6) {
      margin-top: -25rem;
    }

    .staggered-item:nth-child(7) {
      margin-top: -29rem;
    }

    .staggered-item:nth-child(8) {
      margin-top: -0.1rem;
    }

    .staggered-item:nth-child(9) {
      margin-top: -28rem;
    }

    .staggered-item:nth-child(10) {
      margin-top: -24rem;
    }

    .staggered-item:nth-child(11) {
      margin-top: -17rem;
    }
  }

}

@layer base {
  * {
    @apply border-border !scroll-smooth;
  }

  body {
    @apply bg-background text-foreground;
  }

  button {
    @apply active:outline-none;
  }
}

@layer utilities {
  .heading {
    @apply font-bold text-4xl md:text-5xl text-center;
  }
}